<template>
  <v-card tile :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color=""
            size="x-small"
            :to="{
              name: page_route
                ? `${page_route}${id ? '.detalle' : ''}`
                : 'home',
              params: { id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${!loading && data && data.folio ? `${data.folio} | ` : ''}${
                $route.meta.title
              }`
            "
          />
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-form ref="form_submit" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'GENERAL'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="2" v-if="login.role_id === 1">
                    <v-autocomplete
                      label="Usuario*"
                      v-model="data.user_id"
                      :rules="rules.required"
                      :items="users"
                      item-value="id"
                      :item-text="(item) => item.email"
                      :loading="users_loading"
                      @change="userSelected"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="2" v-if="data.user_id">
                    <v-autocomplete
                      label="Aseguradora*"
                      v-model="data.insurance_id"
                      :rules="rules.required"
                      :items="insurances"
                      item-value="insurance_id"
                      :item-text="(item) => item.name"
                      :loading="insurances_loading"
                      @change="getUserProviders(null)"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insurance_id && !data.insured.id">
            <v-card tile :loading="insured_loading" :disabled="insured_loading">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'ASEGURADO'" />
                  <small
                    class="text-caption"
                    v-text="' | Pulsa enter sobre un campo para buscar'"
                  />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="info"
                        icon
                        @click.prevent="valitySearchInsureds"
                      >
                        <v-icon v-text="'mdi-account-search'" />
                      </v-btn>
                    </template>
                    <span v-text="'Buscar asegurado'" />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" :md="find_full_last_name ? 3 : 4">
                    <v-text-field
                      label="ID SM / Póliza"
                      v-model="insured_search.enrollment"
                      type="text"
                      v-on:keyup.enter="valitySearchInsureds"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" :md="find_full_last_name ? 3 : 4">
                    <v-text-field
                      label="Nombre"
                      v-model="insured_search.name"
                      type="text"
                      v-on:keyup.enter="valitySearchInsureds"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" :md="find_full_last_name ? 3 : 4">
                    <v-text-field
                      label="A. Paterno"
                      v-model="insured_search.first_surname"
                      type="text"
                      v-on:keyup.enter="valitySearchInsureds"
                      dense
                    />
                  </v-col>
                  <v-col v-if="find_full_last_name" cols="12" md="3">
                    <v-text-field
                      label="A. Materno"
                      v-model="insured_search.second_surname"
                      type="text"
                      v-on:keyup.enter="valitySearchInsureds"
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      color="info"
                      small
                      @click.prevent="valitySearchInsureds"
                    >
                      <v-icon left v-text="'mdi-account-search'" />
                      Buscar asegurado
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="insured_search_fail && !insured_form">
                    <v-alert type="warning" dense>
                      <v-row align="center">
                        <v-col class="text-center">
                          Asegurado no encontrado en sistema, ¿Desea registrar
                          manualmente la información?
                        </v-col>
                        <v-col class="shrink">
                          <v-btn
                            small
                            v-text="'Aceptar'"
                            @click.prevent="insuredForm"
                          />
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" v-if="error_search_insureds">
                    <v-alert type="warning" dense>
                      <v-row align="center">
                        <v-col class="text-center">
                          Los parámetros de busqueda no son suficientes para
                          encontrar un registro. Asegúrese de llenar
                          correctamente la información.
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured.id">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'ASEGURADO'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip v-if="data.insured.id" left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="warning"
                        icon
                        @click.prevent="insuredRemove"
                      >
                        <v-icon v-text="'mdi-account-reactivate'" />
                      </v-btn>
                    </template>
                    <span v-text="'Volver a buscar'" />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="ID SM / Póliza"
                      v-model="data.enrollment"
                      maxlength="10"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.name"
                      :rules="rules.required"
                      maxlength="50"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="A. Paterno*"
                      v-model="data.first_surname"
                      :rules="rules.required"
                      maxlength="25"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="A. Materno"
                      v-model="data.second_surname"
                      maxlength="25"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="9" class="pt-3">
                    <v-text-field
                      label="Contratante*"
                      v-model="data.contractor"
                      :rules="rules.required"
                      maxlength="255"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-switch
                      v-if="insured_form"
                      class="p-0"
                      label="Titular"
                      v-model="data.policyholder"
                      dense
                    />
                    <ViewData
                      v-else
                      label="Tipo"
                      :value="data.insured.insured_type.insured_type"
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="!data.policyholder">
                    <v-text-field
                      label="Nombre (Titular)*"
                      v-model="data.dependent_name"
                      :rules="rules.required"
                      maxlength="50"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="!data.policyholder">
                    <v-text-field
                      label="A. Paterno (Titular)*"
                      v-model="data.dependent_first_surname"
                      :rules="rules.required"
                      maxlength="25"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="!data.policyholder">
                    <v-text-field
                      label="A. Materno (Titular)"
                      v-model="data.dependent_second_surname"
                      maxlength="25"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="
                      data.insured.contractor.express_letter &&
                      !expired &&
                      type != 2
                    "
                    class="text-center"
                  >
                    <v-alert type="success" dense>
                      El asegurado cuenta con beneficio de carta exprés, al
                      término de registro se generara carta
                    </v-alert>
                  </v-col>
                  <v-col cols="12" v-if="expired" class="text-center">
                    <v-alert type="error" dense>
                      El asegurado tiene la vigencia vencida.
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured.id">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'HOSPITAL'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-col
                  cols="12"
                  md="2"
                  v-if="
                    (type === 3 || (type === 1 && login.role_id == 10)) &&
                    data.user_id &&
                    !providers_loading
                  "
                >
                  <v-autocomplete
                    label="Estado*"
                    v-model="entity_id"
                    :rules="rules.required"
                    :items="entities"
                    item-value="id"
                    :item-text="(i) => i.name"
                    :loading="providers_loading"
                    dense
                    v-on:change="filterMunicipalities"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  v-if="
                    (type === 3 || (type === 1 && login.role_id == 10)) &&
                    data.user_id &&
                    !municipalities_loading
                  "
                >
                  <v-autocomplete
                    label="Municipio*"
                    v-model="municipality_id"
                    :rules="rules.required"
                    :items="municipalities"
                    item-value="id"
                    :item-text="(i) => i.name"
                    :loading="municipalities_loading"
                    dense
                    v-on:change="filterProviders"
                  />
                </v-col>
                <v-col cols="12" md="4" v-if="data.user_id">
                  <v-autocomplete
                    label="Nombre*"
                    v-model="data.provider_id"
                    :rules="rules.required"
                    :items="providers"
                    item-value="id"
                    :item-text="(i) => i.trade_name"
                    :loading="providers_loading"
                    dense
                    v-on:change="changeProvider()"
                  />
                </v-col>
                <v-col
                  v-if="
                    data.petition == 1 && doctor_director && doctor_director.id
                  "
                  cols="12"
                  class="text-center"
                >
                  <v-alert type="success" dense>
                    <small><b v-text="'Médico coordinador: '" /></small>
                    <span
                      v-text="
                        `${doctor_director.name} ${doctor_director.first_surname} ${doctor_director.second_surname}`
                      "
                    />
                    <br />
                    <small><b v-text="'Correo electrónico: '" /></small>
                    <span
                      v-text="
                        doctor_director.email
                          ? doctor_director.email
                          : 'NO REGISTRADO'
                      "
                    />
                  </v-alert>
                </v-col>
                <v-col
                  v-if="
                    data.petition == 1 &&
                    doctor_director &&
                    doctor_director.id == null
                  "
                  cols="12"
                  class="text-center"
                >
                  <v-alert type="warning" dense>
                    Médico coordinador no registrado
                  </v-alert>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured.id">
            <v-card tile :loading="doctor_loading" :disabled="doctor_loading">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'MÉDICO'" />
                  <small
                    v-if="!data.doctor_id"
                    class="text-caption"
                    v-text="' | Pulsa enter sobre un campo para buscar'"
                  />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip v-if="!data.doctor_id" left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="info"
                        icon
                        @click.prevent="doctorSearch"
                      >
                        <v-icon v-text="'mdi-magnify'" />
                      </v-btn>
                    </template>
                    <span v-text="'Buscar médico'" />
                  </v-tooltip>
                  <v-tooltip v-else left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="warning"
                        icon
                        @click.prevent="doctorRemove"
                      >
                        <v-icon v-text="'mdi-refresh'" />
                      </v-btn>
                    </template>
                    <span
                      v-text="
                        'Volver a buscar médico | Registrar solo en formulario'
                      "
                    />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Nombre"
                      v-model="data.doctor_name"
                      maxlength="50"
                      type="text"
                      :disabled="data.doctor_id ? true : false"
                      v-on:keyup.enter="doctorSearch"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="A. paterno"
                      v-model="data.doctor_first_surname"
                      maxlength="25"
                      type="text"
                      :disabled="data.doctor_id ? true : false"
                      v-on:keyup.enter="doctorSearch"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="A. materno"
                      v-model="data.doctor_second_surname"
                      maxlength="25"
                      type="text"
                      :disabled="data.doctor_id ? true : false"
                      v-on:keyup.enter="doctorSearch"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      label="Especialidad"
                      v-model="data.specialty_type_id"
                      :items="specialty_types"
                      item-value="id"
                      :item-text="(item) => item.specialty_type"
                      :loading="specialty_types_loading"
                      :disabled="data.doctor_id ? true : false"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" v-if="!data.doctor_id">
                    <v-btn
                      block
                      color="info"
                      small
                      @click.prevent="doctorSearch"
                    >
                      <v-icon left v-text="'mdi-magnify'" />
                      Buscar médico
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured.id">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'INGRESO'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Núm. de paciente o episodio hospital"
                      v-model="data.episode"
                      maxlength="30"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="type === 1 || type === 3">
                    <v-autocomplete
                      label="Tipo*"
                      v-model="data.rha_admission_type_id"
                      :rules="rules.required"
                      :items="admission_types"
                      item-value="id"
                      :item-text="(item) => item.rha_admission_type"
                      :loading="admission_types_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="type != 2">
                    <DatePicker
                      label="Fecha*"
                      :model.sync="data.admission_date"
                      :rules="rules.required"
                      clean
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-else>
                    <DatePicker
                      label="Fecha*"
                      :model.sync="data.admission_date"
                      :rules="rules.required"
                      clean
                      enableAfterDate
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="type === 1 || type === 3">
                    <v-text-field
                      v-model="data.admission_time"
                      label="Hora*"
                      :rules="rules.required"
                      type="time"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="this.id && login.role_id < 3">
                    <DatePicker
                      label="Fecha ingreso reporte"
                      :model.sync="data.admission_date_report"
                      clean
                      all_date
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="this.id && login.role_id < 3">
                    <DatePicker
                      label="Fecha alta reporte"
                      :model.sync="data.discharge_report"
                      clean
                      all_date
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      rows="1"
                      label="Diagnóstico*"
                      v-model="data.diagnosis"
                      :rules="rules.required"
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      rows="1"
                      label="Procedimiento"
                      v-model="data.process"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Núm. de reporte | Siniestro"
                      v-model="data.sinister_number"
                      maxlength="15"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="type === 1 || type === 3">
                    <v-text-field
                      label="Habitación"
                      v-model="data.room"
                      maxlength="30"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Paciente | Correo electrónico"
                      v-model="data.email"
                      :rules="rules.emailNoRequired"
                      maxlength="80"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Paciente | Teléfono"
                      v-model="data.phone"
                      maxlength="10"
                      type="text"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured.id">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'DOCUMENTOS'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" v-for="(v, i) in data.documents" :key="i">
                    <v-row v-if="v.active">
                      <v-col cols="12" md="3">
                        <v-autocomplete
                          :label="`Documento* (${i + 1})`"
                          v-model="v.document_id"
                          :rules="rules.required"
                          :items="documents"
                          item-value="id"
                          :item-text="(item) => item.document"
                          :loading="documents_loading"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          label="Descripción"
                          v-model="v.description"
                          maxlength="100"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-file-input
                          label="Archivo digital*"
                          v-model="v.file"
                          accept="image/*,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                          :rules="id ? [] : rules.required"
                          show-size
                          dense
                        />
                      </v-col>
                      <v-col cols="12" md="1" class="text-right">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="error"
                              @click="fileRemove(i)"
                            >
                              <v-icon medium v-text="'mdi-file-remove'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar documento (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <v-divider class="pb-1" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          x-small
                          color="warning"
                          :loading="loading"
                          @click="fileAdd"
                        >
                          <v-icon v-text="'mdi-file-plus'" />
                        </v-btn>
                      </template>
                      <span v-text="'Agregar documento'" />
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            class="text-right"
            v-if="data.insured.id && !expired"
          >
            <BtnCircle
              :tLabel="id ? 'Editar' : 'Agregar'"
              tPosition="top"
              :icon="id ? 'mdi-pencil' : 'mdi-plus'"
              :color="id ? 'info' : 'success'"
              size="small"
              :click="formSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog v-model="insured_dialog" scrollable persistent max-width="1000">
      <v-card
        tile
        :loading="insured_dialog_loading"
        :disabled="insured_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Asegurados encontrados'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td v-text="'ID'" />
                      <td v-text="'Asegurado'" />
                      <td v-text="''" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, i) in insureds" :key="i">
                      <td v-text="v.enrollment" />
                      <td
                        v-text="
                          `${v.name} ${v.first_surname} ${v.second_surname}`
                        "
                      />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="warning"
                              @click="addInsured(v.enrollment, v.id)"
                            >
                              <v-icon v-text="'mdi-check'" />
                            </v-btn>
                          </template>
                          <span v-text="'Seleccionar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12">
              <v-alert type="warning" dense>
                <v-row align="center">
                  <v-col class="text-center">
                    Asegurado no encontrado, ¿Desea registrar manualmente la
                    información?
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      small
                      v-text="'Aceptar'"
                      @click.prevent="insuredForm"
                    />
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="doctor_dialog" scrollable persistent max-width="1000">
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Médicos encontrados'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <td v-text="'Médico'" />
                  <td v-text="'Especialidad'" />
                  <td v-text="''" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, i) in doctors" :key="i">
                  <td
                    v-text="`${v.name} ${v.first_surname} ${v.second_surname}`"
                  />
                  <td
                    v-text="v.specialty_type_name ? v.specialty_type_name : '-'"
                  />
                  <td>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          small
                          color="warning"
                          @click="doctorData(i)"
                        >
                          <v-icon v-text="'mdi-check'" />
                        </v-btn>
                      </template>
                      <span v-text="'Seleccionar'" />
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider />
      </v-card>
    </v-dialog>

    <v-dialog v-model="rha_found_show" scrollable persistent max-width="1000">
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Coincidencias encontradas'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="eraseInsured">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <td v-text="'Folio'" />
                  <td v-text="'Asegurado'" />
                  <td v-text="'F. ingreso'" />
                  <td v-text="'Tipo'" />
                  <td v-text="'Acción'" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rha_found, i) in rha_founds" :key="i">
                  <td v-text="base_folio + rha_found.id" />
                  <td
                    v-text="
                      `${rha_found.name} ${rha_found.first_surname} ${rha_found.second_surname}`
                    "
                  />
                  <td v-text="rha_found.admission_date" />
                  <td v-text="rha_found.rha_type.rha_type" />
                  <td>
                    <v-tooltip top v-if="rha_found.rha_type_id === 2">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="ml-1"
                          fab
                          x-small
                          color="warning"
                          dark
                          @click="setProgrammed(rha_found)"
                        >
                          <v-icon v-text="'mdi-swap-horizontal'" />
                        </v-btn>
                      </template>
                      <span v-text="'Ejecutar programación'" />
                    </v-tooltip>

                    <v-tooltip top v-if="rha_found.insured_id === null">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="ml-1"
                          fab
                          x-small
                          color="orange darken-3"
                          dark
                          @click="insuredDataNull(rha_found.id)"
                        >
                          <v-icon v-text="'mdi-swap-horizontal'" />
                        </v-btn>
                      </template>
                      <span v-text="'Continuar con este registro'" />
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          <v-row justify="end">
            <v-btn
              depressed
              color="primary"
              @click.prevent="insuredData(insured_id)"
            >
              Continuar con el registro
            </v-btn>
          </v-row>
        </v-card-text>
        <v-divider />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rha_coinsidents_found_show"
      scrollable
      persistent
      max-width="1200"
    >
      <v-card tile>
        <v-toolbar dark dense color="red darken-2">
          <v-toolbar-title>
            Este asegurado ya esta hospitalizado, con los datos que a
            continuación se describen
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rha_coinsidents_found_show = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <td v-text="'Folio'" />
                  <td v-text="'ID SM / Póliza'" />
                  <td v-text="'Asegurado'" />
                  <td v-text="'Vigencia'" />
                  <td v-text="'Hospital'" />
                  <td v-text="'Municipio'" />
                  <td v-text="'Alta'" />
                  <td v-text="'F. ingreso'" />
                  <td v-text="'Tipo'" />
                  <td v-text="''" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rha_found, i) in rha_coinsidents_found" :key="i">
                  <td v-text="base_folio + rha_found.id" />
                  <td
                    v-text="rha_found.enrollment"
                    :class="
                      rha_found.insured_id ? '' : 'orange--text text--darken-3'
                    "
                  />
                  <td
                    v-text="
                      `${rha_found.name} ${rha_found.first_surname} ${rha_found.second_surname}`
                    "
                    :class="
                      rha_found.insured_id ? '' : 'orange--text text--darken-3'
                    "
                  />
                  <td
                    v-text="
                      rha_found.insured_id ? rha_found.insured.validity : ''
                    "
                  />
                  <td v-text="rha_found.provider.trade_name" />
                  <td
                    v-text="
                      rha_found.provider.municipality.municipality +
                      ', ' +
                      rha_found.provider.entity.abbreviation +
                      '.'
                    "
                  />
                  <td
                    v-text="
                      rha_found.discharge ? rha_found.discharge : 'Pendiente'
                    "
                  />
                  <td v-text="rha_found.admission_date" />
                  <td v-text="rha_found.rha_type.rha_type" />
                  <td>
                    <v-tooltip top v-if="rha_found.rha_type_id === 2">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="ml-1"
                          fab
                          x-small
                          color="warning"
                          dark
                          @click="setProgrammed(rha_found)"
                        >
                          <v-icon v-text="'mdi-swap-horizontal'" />
                        </v-btn>
                      </template>
                      <span v-text="'Ejecutar programación'" />
                    </v-tooltip>

                    <v-tooltip top v-if="rha_found.change_to_opening">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="ml-1"
                          fab
                          x-small
                          color="pink darken-3"
                          dark
                          @click="setShowOpenig(rha_found.id)"
                        >
                          <v-icon v-text="'mdi-eye'" />
                        </v-btn>
                      </template>
                      <span v-text="'Hacer visible en aperturas'" />
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          <v-row justify="end">
            <v-btn depressed color="warning" @click="insuredConfirmSearch">
              Continuar
            </v-btn>
          </v-row>
        </v-card-text>
        <v-divider />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="programmed_to_rha"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card tile :loading="loading" :disabled="loading">
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="`Cambiar programado a ingreso hospitalario`"
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="programmed_to_rha = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form v-on:submit.prevent ref="form_programmed" lazy-validation>
            <v-row>
              <v-col cols="12" />
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2
                      class="text-caption"
                      v-text="
                        'Mover a ingreso hospitalario la programación ' +
                        programmed_to_rha_data.folio +
                        '.'
                      "
                    />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field
                          v-model="programmed_to_rha_data.admission_time"
                          label="Hora*"
                          :rules="rules.required"
                          type="time"
                          dense
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          label="Habitación"
                          v-model="programmed_to_rha_data.room"
                          maxlength="30"
                          type="text"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <BtnCircle
                  tLabel="Aceptar"
                  tPosition="top"
                  icon="mdi-check"
                  color="success"
                  size="x-small"
                  :click="programmedToRhaSubmit"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { show, index, storeUpdate } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      rules: rules(),
      page_route: null,
      type: null,
      login: this.$store.getters.getLogin,
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      users: [],
      users_loading: true,
      admission_types: [],
      admission_types_loading: true,
      specialty_types: [],
      specialty_types_loading: true,
      documents: [],
      documents_loading: true,
      programmed_to_rha: false,
      programmed_to_rha_data: {
        id: null,
        folio: "",
        admission_time: null,
        room: "",
        type: 1,
      },
      data: {
        id: null,
        user_id: null,
        insurance_id: null,
        provider_id: null,
        insured_id: null,
        insured: { id: null, contractor: {} },
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        office_address: "",
        policy: "",
        credential: "",
        contractor: "",
        policyholder: true,
        dependent_name: "",
        dependent_first_surname: "",
        dependent_second_surname: "",
        doctor_id: null,
        doctor_name: "",
        doctor_first_surname: "",
        doctor_second_surname: "",
        specialty_type_id: -1,
        rha_admission_type_id: null,
        admission_date: "",
        admission_time: "",
        diagnosis: "",
        process: "",
        room: "",
        email: "",
        phone: "",
        programmed: 0,
        programmed_active: 0,
        documents: [],
        petition: 0,
        sinister_number: "",
        episode: "",
      },
      loading: false,
      insurances: [],
      insurances_loading: true,
      providers: [],
      providers_loading: false,
      insureds: [],
      insured_search: {
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        insurance_id: null,
        user_id: this.$store.getters.getLogin.id,
        provider_id: null,
        insurance_id: null,
      },
      insured_search_fail: false,
      insured_dialog: false,
      insured_dialog_loading: false,
      insured_loading: false,
      insured_form: false,
      doctors: [],
      doctor_dialog: false,
      doctor_loading: false,
      doctor_director: null,
      rha_founds: [],
      rha_found_show: false,
      rha_coinsidents_found: [],
      rha_coinsidents_found_show: false,
      insured_id: null,
      entities: null,
      entity_id: -1,
      municipalities_all: null,
      municipalities: null,
      municipalities_loading: true,
      municipality_id: -1,
      providers_all: [],
      base_folio: 0,
      expired: false,
      error_search_insureds: false,
      find_full_last_name: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    userSelected() {
      this.insurances = [];
      this.data.insurance_id = null;
      this.providers = [];
      this.data.provider_id = null;

      this.insurances_loading = true;

      index(
        "user/" + this.data.user_id + "/insurances",
        this.login.token,
        []
      ).then((response) => {
        this.insurances = response.data;

        if (this.insurances.length == 1) {
          this.data.insurance_id = this.insurances[0].id;
        }

        this.insurances_loading = false;

        if (this.type !== 3) {
          this.getUserProviders(null);
        }
      });

      //this.getUserProviders();

      if (this.type == 3 || (this.type == 1 && this.login.role_id == 10)) {
        this.providers_loading = true;

        index("rhas/petition_providers", this.login.token, []).then(
          (response) => {
            //console.log(response.data);
            this.providers = response.data.providers;
            this.providers_all = this.providers;

            this.entities = response.data.entities;
            this.municipalities_all = response.data.municipalities;

            this.providers_loading = false;
          }
        );
      }
    },
    getUserProviders(insured_id) {
      if (this.type !== 3 && this.data.insurance_id) {
        this.providers_loading = true;
        this.find_full_last_name = false;

        Axios.get(
          URL_API + "/insurances/" + this.data.insurance_id,
          headersToken(this.login.token)
        ).then((response) => {
          this.find_full_last_name = response.data.data.find_full_last_name;

          let api =
            "user/" +
            this.data.user_id +
            "/insurances/" +
            this.data.insurance_id +
            "/providers";

          if (insured_id && this.type == 2) {
            api += "?insured_id=" + insured_id;
          }

          index(api, this.login.token, []).then((response) => {
            this.providers = response.data;

            if (this.providers.length == 1) {
              this.data.provider_id = this.providers[0].id;
            }

            this.providers_loading = false;
          });
        });
      }
    },
    filterMunicipalities() {
      this.municipalities = null;
      this.municipality_id = -1;
      this.municipalities_loading = true;

      this.municipalities = this.municipalities_all.filter(
        (i) => i.entity_id === this.entity_id || i.entity_id === -1
      );
      this.municipalities_loading = false;

      this.filterProviders();
    },
    filterProviders() {
      this.data.provider_id = null;
      this.provides_loading = true;

      if (this.entity_id === -1 && this.municipality_id === -1) {
        this.providers = this.providers_all;
      }

      if (this.entity_id > 0 && this.municipality_id === -1) {
        this.providers = this.providers_all.filter(
          (i) => i.entity_id === this.entity_id
        );
      }

      if (this.entity_id > 0 && this.municipality_id > 0) {
        this.providers = this.providers_all.filter(
          (i) =>
            i.entity_id === this.entity_id &&
            i.municipality_id === this.municipality_id
        );
      }

      if (this.providers.length == 1) {
        this.data.provider_id = this.providers[0].id;
        this.municipality_id = this.providers[0].municipality_id;
      }

      this.provides_loading = true;
    },
    changeProvider() {
      if (this.data.petition) {
        show("doctors/provider", this.login.token, this.data.provider_id).then(
          (response) => {
            this.doctor_director = response;
            if (!this.doctor_director) this.doctor_director = { id: null };
          }
        );
      }
    },
    insuredConfirmSearch() {
      this.$swal
        .fire(
          msgConfirm(
            "Si continúa el registro con estos datos el caso se duplicará ¿Confirma continuar?"
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.insuredSearch();
          }
        });
    },
    insuredSearch() {
      this.rha_coinsidents_found_show = false;
      if (
        this.insured_search.enrollment != "" ||
        this.insured_search.name != "" ||
        this.insured_search.first_surname != "" ||
        this.insured_search.second_surname != ""
      ) {
        this.insured_loading = true;
        this.insured_search_fail = false;

        this.insured_search.insurance_id = this.data.insurance_id;
        this.insured_search.provider_id = this.data.provider_id;

        Axios.post(
          URL_API + "/insureds/search",
          this.insured_search,
          headersToken(this.login.token)
        ).then((response) => {
          this.insureds = response.data.data;

          if (this.insureds.length === 1) {
            this.insured_id = this.insureds[0].id;
            this.insuredData(this.insureds[0].id);
            this.addInsured(this.insureds[0].enrollment, this.insureds[0].id);
          } else {
            if (this.insureds.length === 0) {
              this.insured_search_fail = true;
            } else {
              this.insured_dialog = true;
            }
          }

          this.insured_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "warning",
            "Utiliza al menos un campo para la búsqueda de asegurado"
          )
        );
      }
    },
    addInsured(enrollment, id) {
      show("rha/insured", this.login.token, enrollment).then((response) => {
        // if (response.length > 0) {
        //   this.rha_founds = response;
        //   if (this.rha_founds.length > 0)
        //     this.insured_id = response[0].insured_id;
        //   this.rha_found_show = true;
        // } else {
        this.insuredData(id);
        // }
      });
    },
    valitySearchInsureds() {
      this.error_search_insureds = false;
      this.insured_search_fail = false;
      this.insured_search.provider_id = this.data.provider_id;
      this.insured_search.insurance_id = this.data.insurance_id;

      if (this.insured_search.enrollment) {
        this.searchRHAInsureds();
      } else {
        if (this.insured_search.name && this.insured_search.first_surname) {
          if (this.find_full_last_name) {
            if (this.insured_search.second_surname) {
              this.searchRHAInsureds();
            } else {
              this.error_search_insureds = true;
            }
          } else {
            this.searchRHAInsureds();
          }
        } else {
          this.error_search_insureds = true;
        }
      }
    },
    searchRHAInsureds() {
      Axios.post(
        URL_API + "/rha/insured/search",
        this.insured_search,
        headersToken(this.login.token)
      ).then((response) => {
        this.error_search_insureds = false;
        this.rha_coinsidents_found = response.data.data;
        if (this.rha_coinsidents_found.length > 0)
          this.rha_coinsidents_found_show = true;
        else this.insuredSearch();
      });
    },
    insuredData(id) {
      this.insured_dialog_loading = true;

      show("insureds", this.login.token, id).then((rsp) => {
        this.data.insured = rsp;
        this.data.insured_id = rsp.id;
        this.data.enrollment = rsp.enrollment;
        this.data.name = rsp.name;
        this.data.first_surname = rsp.first_surname;
        this.data.second_surname = rsp.second_surname;
        this.data.office_address = rsp.office_address;
        this.data.policy = rsp.policy;
        this.data.credential = rsp.credential;
        this.data.contractor = rsp.contractor.name;
        this.expired = rsp.expired;
        this.getUserProviders(this.data.insured_id);

        this.insured_dialog = false;
        this.insured_dialog_loading = false;
        this.rha_found_show = false;
      });
    },
    insuredDataNull(id) {
      this.insured_dialog_loading = true;

      show("rha", this.login.token, id).then((rsp) => {
        this.data.insured = {};
        this.data.insured.contractor = { express_letter: false };
        this.data.insured_id = rsp.insured_id;
        this.data.enrollment = rsp.enrollment;
        this.data.name = rsp.name;
        this.data.first_surname = rsp.first_surname;
        this.data.second_surname = rsp.insured.second_surname;
        this.data.office_address = rsp.insured.office_address;
        this.data.policy = rsp.rsp;
        this.data.credential = rsp.credential;
        this.data.contractor = rsp.contractor;
        // this.expired = false;

        this.data.insured.id = -1;

        this.insured_form = true;

        this.insured_dialog = false;
        this.insured_dialog_loading = false;
        this.rha_found_show = false;
      });
    },
    eraseInsured() {
      this.rha_found_show = false;
      this.data.insured = {};
    },
    insuredForm() {
      this.insured_dialog = false;
      this.data.enrollment = this.insured_search.enrollment;
      this.data.name = this.insured_search.name;
      this.data.first_surname = this.insured_search.first_surname;
      this.data.second_surname = this.insured_search.second_surname;
      this.data.insured.id = -1;
      //this.data.provider_id = null;

      this.insured_form = true;
    },
    insuredRemove() {
      this.insured_form = false;

      this.data.insured_id = null;
      this.data.insured = { id: null, contractor: {} };
      this.data.enrollment = "";
      this.data.name = "";
      this.data.first_surname = "";
      this.data.second_surname = "";
      this.data.office_address = "";
      this.data.policy = "";
      this.data.credential = "";
      this.data.contractor = "";
      this.data.policyholder = true;
      this.data.dependent_name = "";
      this.data.dependent_first_surname = "";
      this.data.dependent_second_surname = "";
    },
    doctorSearch() {
      if (
        this.data.doctor_name != "" ||
        this.data.doctor_first_surname != "" ||
        this.data.doctor_second_surname != ""
      ) {
        this.doctor_loading = true;

        Axios.post(
          URL_API + "/doctors/search",
          {
            name: this.data.doctor_name,
            first_surname: this.data.doctor_first_surname,
            second_surname: this.data.doctor_second_surname,
          },
          headersToken(this.login.token)
        ).then((response) => {
          this.doctors = response.data.data;

          if (this.doctors.length === 1) {
            this.doctorData(0);
          } else {
            if (this.doctors.length === 0) {
              this.$swal.fire(
                msgAlert("warning", "No se encontro ningun médico")
              );
            } else {
              this.doctor_dialog = true;
            }
          }

          this.doctor_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "warning",
            "Utiliza al menos un campo para la búsqueda de médico"
          )
        );
      }
    },
    doctorData(i) {
      this.doctor_loading = true;

      this.data.doctor_id = this.doctors[i].id;
      this.data.doctor_name = this.doctors[i].name;
      this.data.doctor_first_surname = this.doctors[i].first_surname;
      this.data.doctor_second_surname = this.doctors[i].second_surname;
      this.data.specialty_type_id = this.doctors[i].specialty_type_id;

      this.doctor_dialog = false;
      this.doctor_loading = false;
    },
    doctorRemove() {
      this.data.doctor_id = null;
      this.data.doctor_name = "";
      this.data.doctor_first_surname = "";
      this.data.doctor_second_surname = "";
      this.data.specialty_type_id = -1;
    },
    fileAdd() {
      this.data.documents.push({
        id: null,
        active: 1,
        document_id: null,
        description: "",
        file: null,
      });
    },
    fileRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
    setProgrammed(rha) {
      this.programmed_to_rha_data.id = rha.id;
      this.programmed_to_rha_data.folio = this.base_folio + rha.id;
      this.programmed_to_rha_data.room = "";
      this.programmed_to_rha_data.admission_time = null;
      this.programmed_to_rha = true;
    },
    setShowOpenig(id) {
      this.$swal
        .fire(msgConfirm(`¿Confirma el cambio en el registro?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            storeUpdate(
              "rhas/show/openings",
              this.login.token,
              this.id,
              { id: id, user_id: this.login.id },
              this.login.id
            ).then((response) => {
              //console.log(response);

              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                if (this.id) {
                  this.$router.push({
                    name: `${this.page_route}.detalle`,
                    params: {
                      id: this.id,
                    },
                  });
                } else {
                  this.$router.push({
                    name: this.page_route,
                    params: {
                      new_rha_id: response.new_rha_id,
                      express_letter: response.express_letter,
                    },
                  });
                }
              } else {
                console.log(response.data.message);
              }
            });
            // this.loading = false;
          }
        });
    },
    programmedToRhaSubmit() {
      if (this.$refs.form_programmed.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${this.id ? "edición" : "creación"} del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              storeUpdate(
                "rhas/type",
                this.login.token,
                this.id,
                this.programmed_to_rha_data,
                this.login.id
              ).then((response) => {
                //console.log(response);

                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  if (this.id) {
                    this.$router.push({
                      name: `${this.page_route}.detalle`,
                      params: {
                        id: this.id,
                      },
                    });
                  } else {
                    this.$router.push({
                      name: this.page_route,
                      params: {
                        new_rha_id: response.new_rha_id,
                        express_letter: response.express_letter,
                      },
                    });
                  }
                } else {
                  console.log(response.data.message);
                }
              });
              // this.loading = false;
            }
          });
      }
    },
    formSubmit() {
      if (this.$refs.form_submit.validate()) {
        if (this.data.episode) {
          this.storeUpdate();
        } else {
          this.$swal
            .fire(
              msgConfirm(
                `No se cuenta con "Núm. de paciente o episodio hospital", ¿Desea continuar?`
              )
            )
            .then((response) => {
              if (response.isConfirmed) {
                this.storeUpdate();
              }
            });
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    storeUpdate() {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma la ${this.id ? "edición" : "creación"} del registro?`
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            let obj = Object.assign({}, this.data);
            obj.search_for_enrollment = this.insured_search.enrollment
              ? true
              : false;

            storeUpdate(
              "rha",
              this.login.token,
              this.id,
              obj,
              this.login.id
            ).then((response) => {
              //console.log(response);

              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                if (this.id) {
                  this.$router.push({
                    name: `${this.page_route}.detalle`,
                    params: {
                      id: this.id,
                    },
                  });
                } else {
                  this.$router.push({
                    name: this.page_route,
                    params: {
                      new_rha_id: response.new_rha_id,
                      express_letter: response.express_letter,
                    },
                  });
                }
              } else {
                console.log(response.data.message);
              }
            });
            // this.loading = false;
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    switch (this.page_route) {
      case "admission_request":
        this.type = 1;
        break;
      case "programaciones":
        this.type = 2;
        break;
      case "aperturas":
        this.type = 3;
        break;
      case "facturacion":
        this.type = 4;
        break;
    }

    index("rha/getbasefolio", this.login.token, []).then((response) => {
      this.base_folio = response.data;
    });

    catalog("rha_admission_types", this.login.token).then((response) => {
      this.admission_types = response;

      this.admission_types.splice(4, 1);

      if (this.type === 1 || this.type === 3) {
        this.admission_types.splice(3, 1);
      }

      this.admission_types_loading = false;
    });

    catalog("specialty_types", this.login.token).then((response) => {
      this.specialty_types = response;
      this.specialty_types.push({
        id: -1,
        specialty_type: "NINGUNA",
      });

      this.specialty_types_loading = false;
    });

    index("documents/1", this.login.token, []).then((response) => {
      this.documents = response.data;
      this.documents_loading = false;
    });

    if (this.id) {
      this.loading = true;

      show("rha", this.login.token, this.id).then((response) => {
        this.data = response;

        this.data.specialty_type_id = this.data.specialty_type_id
          ? this.data.specialty_type_id
          : -1;

        this.insured_search.enrollment = this.data.enrollment;
        this.insured_search.name = this.data.name;
        this.insured_search.first_surname = this.data.first_surname;
        this.insured_search.second_surname = this.data.second_surname;

        this.insured_form = this.data.insured_id ? false : true;

        this.userSelected();
        // if (this.type !== 3) {
        //   this.getUserProviders();
        // }
        //this.doctorSearch();

        this.loading = false;
      });
    } else {
      this.data.rha_type_id = this.type;
    }

    if (this.login.role_id === 1) {
      index("general/users", this.login.token, []).then((response) => {
        this.users = response.data;
        this.users_loading = false;
      });
    } else {
      this.data.user_id = this.login.id;
      this.userSelected();
      // if (this.type !== 3) {
      //   this.getUserProviders();
      // }
      this.$forceUpdate();
    }

    this.data.created_by_id = this.login.id;
    this.data.updated_by_id = this.login.id;

    if (this.type === 2) {
      this.data.rha_admission_type_id = 4;
      this.data.admission_time = "00:00:00";
      this.data.room = "";
      this.data.programmed = 1;
      this.data.programmed_active = 1;
    }

    this.data.petition = this.type === 3 ? 1 : 0;
    this.insured_search.rha_type_id = this.type;
  },
};
</script>